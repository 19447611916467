<template>
  <div
    :class="[
      isBusy ? '' : background,
      'rounded-md relative flex items-center overflow-hidden px-12',
      sizeClass,
    ]"
  >
    <RevSkeleton
      v-if="isBusy"
      :class="['absolute bottom-0 left-0 right-0 top-0', background]"
      shape="line"
    />

    <div
      :class="[
        isBusy ? 'opacity-0' : 'opacity-100',
        'flex grow transition-opacity duration-300',
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'

import { RevSkeleton } from '@ds/components/Skeleton'

defineProps<{
  isBusy: boolean
  background: string
}>()

const data = useAttrs()

// do not add min-h-X if a custom size is provided
const sizeClass = computed(() => {
  return 'class' in data &&
    typeof data.class === 'string' &&
    data.class.includes('min-h')
    ? ''
    : 'min-h-64 md:min-h-48'
})
</script>
