<template>
  <div class="flex w-full flex-col justify-end md:w-auto">
    <PaymentSubmitButton
      class="min-w-full md:min-w-160"
      :form="form"
      full-width="never"
      :loading="loading"
    >
      <template v-if="Boolean(providerName)" #default>
        {{
          i18n(translations.submitButton, {
            providerName,
          })
        }}
      </template>
    </PaymentSubmitButton>
    <SecurityReassuranceCaption />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import type { PaymentMethodFormInstance } from '../../../form-common/types/PaymentMethodFormInstance'
import { paymentGroupProviderName } from '../../../methods/helpers/methods'

import PaymentSubmitButton from './PaymentSubmitButton.vue'
import translations from './PaymentSubmitButtonVNext.translations'
import SecurityReassuranceCaption from './components/SecurityReassuranceCaption.vue'

const props = defineProps<{
  form: PaymentMethodFormInstance | null
  loading?: boolean
}>()

const i18n = useI18n()

const providerName = computed(() => {
  const method = props.form?.method
  if (method) {
    return paymentGroupProviderName(method.group, i18n)
  }

  return null
})
</script>
